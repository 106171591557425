import { getAxiosDataGetNode, getAxiosDataNode } from '@/services/apiConfig';
import { AoiRegion, DeleteMessage, UserInfo } from '@/types/store';
import { Result } from '@/types/services';
/* eslint-disable @typescript-eslint/naming-convention */

/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/

// 账户详情-项目列表
async function getAccountDetailsSvc(params = {}): Promise<any> {
  // const url = 'getUserMidByUser';
  // const getData = await getAxiosData(params, url, baseUrl);
  const getData = await getAxiosDataGetNode(params, 'usermid/getUserMidByUser');
  const res = getData as Result<any>;
  return res;
}
// 账户详情-用户列表
async function getUserDetailsSvc(params = {}): Promise<any> {
  const url = 'usermid/getUserDataS';
  // const getData = await getAxiosData(params, url, baseUrl);
  const getData = await getAxiosDataGetNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 账户详情-用户状态调整
async function changeUserStatusSvc(params: {
  en_tel: string
  status: number
}): Promise<any> {
  // const url = 'updateUserStatusByTel';
  // const getData = await getAxiosDatas(params, url, baseUrl);
  const url = 'user/updateUserStatusByTel';
  const getData = await getAxiosDataNode(params, url);

  const res = getData as Result<any>;
  return res;
}
// 账户详情-用户列表编辑
async function UserListEditSvc(params: { en_tel: string }): Promise<any> {
  // const url = 'getUserInfoEdit';
  // const getData = await getAxiosData(params, url, 'login_api/');
  const url = 'user/getUserInfoEdit';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户功能权限

async function UserPermissionSvc(params: { en_tel: string, tel: string }): Promise<any> {
  // const url = 'getUserPagePermission';
  // const getData = await getAxiosData(params, url, baseUrl);
  const url = 'user/getUserPagePermission';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户权限调整更新
async function changeUserpermissionSvc(params: {
  en_tel: string
  tel: string
  user: string, privilege: number
}): Promise<any> {
  const url = 'user/updateUserByTels';
  const getData = await getAxiosDataNode(params, url);
  // const getData = await getAxiosDatas(params, url, baseUrl);

  const res = getData as Result<any>;
  return res;
}
// 新增用户权限设定
// eslint-disable-next-line max-len
async function addUserPermissionByUserTelSvc(params: { tel: string, en_tel: string, user: string, privilege: number }): Promise<any> {
  const url = 'user/addUserByUserTel';
  const getData = await getAxiosDataNode(params, url);
  // const getData = await getAxiosDatas(params, url, baseUrl);
  const res = getData as Result<any>;
  return res;
}
// 用户功能权限（30+）增减
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function updateUserpermissionSvc(params: any): Promise<any> {
  const url = 'user/addUserPagePermissions';
  const getData = await getAxiosDataNode(params, url);
  // const getData = await getAxiosDatas(params, url, baseUrl);
  const res = getData as Result<any>;
  return res;
}
// 新手机号添加及服务绑定
async function appendNewPhoneNumberSvc(params: { tel: string }): Promise<any> {
  const url = 'user/getUserInfo';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 系统审核aoi区域查询
async function getAoiRegionSvc(params: AoiRegion): Promise<any> {
  const url = 'aoi/getAoiRegion';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 客流来源信息删除
async function deleteAoiMesById(params: DeleteMessage): Promise<any> {
  const url = 'aoi/updateAoiRegionById';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户管理-用户获取
async function getUserInfoSvc(params: UserInfo): Promise<any> {
  const url = 'user/getUser';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户管理-用户获取
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function getUserByKeySvc(params: any): Promise<any> {
  const url = 'usermid/getUserMidByUserKey';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// getAllEnterpriseServices
// 用户管理-关联服务
async function getAllEnterpriseSvc(params = {}): Promise<any> {
  const url = 'enterprise/getAllEnterpriseServices';
  const getData = await getAxiosDataGetNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户管理-添加用户信息
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function addUserByTelSvc(params: any): Promise<any> {
  const url = 'user/addUserByTel';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 用户功能权限（30+）增减
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function updateUserpermissionsSvc(params: any): Promise<any> {
  const url = 'user/addUserPagePermission';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 用户权限调整更新-用用户管理
async function changeUserpermissionsSvc(params: {
  tel: string
  en_tel: string
  user: string,
  privilege: number,
  en_key: string
}): Promise<any> {
  const url = 'user/updateUserByTel';
  const getData = await getAxiosDataNode(params, url);

  const res = getData as Result<any>;
  return res;
}
// api监控初始化接口getApiLogList
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function getApiLogListSvc(params: any): Promise<any> {
  const url = 'apilog/getApiLogList';
  const getData = await getAxiosDataGetNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// api监控用户key查询
async function searchInfoByApiKeySvc(params: {
  user_key: string
}): Promise<any> {
  const url = 'apilog/getApiLogByUserKey';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// /getApiLog 企业日监控主页面接口
async function getApiLogSvc(params: {
  page: number,
  rows: number,
  search_type: number,
  search_str: string,
}): Promise<any> {
  const url = 'apilog/getApiLog';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 获取mall服务周期
async function getMallServiceStartAndEnd(params: {
  mall_id: number,
}): Promise<any> {
  const url = 'mall/getMallServiceStartAndEnd';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
// 获取数据生产状态
async function getMallDataProduceStatus(): Promise<any> {
  const url = 'mall/getMallDataProduceStatus';
  const getData = await getAxiosDataGetNode({}, url);
  const res = getData as Result<any>;
  return res;
}
export {
  getAccountDetailsSvc as accountDetailsSvc,
  getUserDetailsSvc,
  changeUserStatusSvc,
  UserListEditSvc,
  UserPermissionSvc,
  changeUserpermissionSvc,
  updateUserpermissionSvc,
  appendNewPhoneNumberSvc,
  addUserPermissionByUserTelSvc,
  getAoiRegionSvc,
  deleteAoiMesById,
  getUserInfoSvc,
  getUserByKeySvc,
  getAllEnterpriseSvc,
  addUserByTelSvc,
  updateUserpermissionsSvc,
  changeUserpermissionsSvc,
  searchInfoByApiKeySvc,
  getApiLogListSvc,
  getApiLogSvc,
  getMallServiceStartAndEnd,
  getMallDataProduceStatus,
};
